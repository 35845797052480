<template>
  <!-- 第三方账户登录 -->
  <div class="third-party">
    <el-button
      v-for="(item, index) in thirdData"
      :key="index"
      @click="otherLogin(item)"
    >{{ item.label }}</el-button>
  </div>
</template>

<script>
export default {
  data() {
    return {
      thirdData: [
        {
          id: 'sequence',
          label: '序列号登录'
        },
        {
          id: 'WeChat',
          label: '微信登录'
        },
        {
          id: 'QQ',
          label: 'QQ登录'
        },
        {
          id: 'other',
          label: '其他登录'
        }
      ]
    }
  },
  methods: {
    /**
     * 第三方账户登录
     * @param value 第三方账户名称
     */
    otherLogin(value) {
      // 微信
      this.$emit('change', value.id)
    }
  }
}
</script>

<style lang="scss" scoped>
.third-party {
  padding: 30px;
  margin-top: 20px;

  .el-button {
    width: 100%;
    margin: 8px 20px;
  }
}
</style>
