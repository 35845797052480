import request from '../plugins/request'

// 登录，获取token
export function login(data) {
  return request({
    url: '/huiyue-cloud-authserver/auth/login',
    method: 'post',
    params: data
  })
}

// 检查当前是否已经登录
export function isLogin() {
  return request({
    url: '/huiyue-cloud-authserver/auth/isLogin',
    method: 'post'
  })
}

// 获取微信状态码
export function getWxState() {
  return request({
    url: '/huiyue-cloud-authserver/wx/state',
    method: 'get'
  })
}

// 微信登录
export function wxLogin(data) {
  return request({
    url: '/huiyue-cloud-authserver/wx/login',
    method: 'get',
    params: data
  })
}

// 绑定微信登录
export function bindWeChat(data) {
  return request({
    url: '/huiyue-cloud-authserver/wx/bind',
    method: 'get',
    params: data
  })
}
