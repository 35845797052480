<template>
  <div id="app">
    <login />
  </div>
</template>

<script>
import login from './views/login.vue'

export default {
  name: 'App',
  components: {
    login
  }
}
</script>

<style></style>
