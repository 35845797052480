import axios from 'axios'
// import { Message } from 'element-ui'

/**
 * 创建axios实例
 */
const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API, // url = 基础 url + 请求 url
  // withCredentials: true, // 跨域请求时发送cookies
  timeout: 5000 // 请求超时
})
// 请求拦截器
service.interceptors.request.use(
  config => {
    // 在发送请求之前做些什么
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    // config.headers['satoken'] = localStorage.getItem('satoken')
    for (let i in localStorage) {
      if (i.split('_')[0] === 'token') {
        config.headers[i] = localStorage[i]
      }
    }
    return config
  },
  error => {
    // 处理请求错误
    return Promise.reject(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  response => {
    if (response.request.responseURL.indexOf('oauth/token')) {
      return response.data
    } else {
      const res = response.data
      return res
    }
  },
  error => {
    // Message({
    //   message: error.response.statusText + error.response.status,
    //   type: 'error',
    //   duration: 5 * 1000
    // })
    return Promise.reject(error.response)
  }
)

export default service
