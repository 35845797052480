<template>
  <!-- 登录表单 -->
  <el-form
    ref="loginForm"
    :model="loginForm"
    :rules="loginRules"
    class="login-form"
    auto-complete="on"
    label-position="left"
  >
    <el-button type="text" class="huanying-denglu">欢迎登录</el-button>
    <el-form-item prop="username">
      <el-input
        ref="username"
        v-model="loginForm.username"
        prefix-icon="el-icon-user"
        placeholder="请输入用户名"
        name="username"
        type="text"
        tabindex="1"
        auto-complete="on"
      />
    </el-form-item>

    <el-form-item prop="password">
      <el-input
        :key="passwordType"
        ref="password"
        v-model="loginForm.password"
        :type="passwordType"
        prefix-icon="el-icon-suitcase-1"
        placeholder="请输入登录密码"
        name="password"
        tabindex="2"
        auto-complete="on"
        @keyup.enter.native="handleLogin"
      />
      <span class="show-pwd" @click="showPwd">
        <el-image
          :src="
            passwordType === 'password'
              ? require('@/assets/img/eye.png')
              : require('@/assets/img/eye-open.png')
          "
        />
      </span>
    </el-form-item>

    <el-form-item>
      <el-checkbox v-model="loginForm.checkbox" @change="changeClick">
        30内自动登录
      </el-checkbox>
    </el-form-item>

    <el-button
      :loading="loading"
      type="primary"
      @click.native.prevent="handleLogin"
    >
      登录
    </el-button>

    <el-button type="text" @click.native.prevent="forgetPassword">
      忘了密码？
    </el-button>
  </el-form>
</template>

<script>
import Cookies from 'js-cookie'
import { login, isLogin, wxLogin, bindWeChat } from '../api/login'
import { Message } from 'element-ui'

export default {
  data() {
    return {
      loginForm: {
        username: '',
        password: '',
        checkbox: false
      },
      loginRules: {
        username: [
          { required: true, trigger: 'blur', message: '请输入用户名' }
        ],
        password: [
          { required: true, trigger: 'blur', message: '请输入密码' },
          { min: 6, max: 18, message: '长度在 6 到 18 个字符', trigger: 'blur' }
        ]
      },
      loading: false,
      passwordType: 'password'
    }
  },
  mounted() {
    this.getCookies()
    if (
      location.search.length > 0 &&
      location.search.includes('code') &&
      location.search.includes('state')
    ) {
      let url = location.search
      let param = {}
      url.split('?')[1].split('&').forEach(item => {
        param[item.split('=')[0]] = item.split('=')[1]
        localStorage.setItem(item.split('=')[0], item.split('=')[1])
      })

      if (location.search.includes('token_authserver')) {
        this.bindWxLogin({ code: param.code, state: param.state }).then(res => {
          const { code, data, msg } = res
          if (code === 200) {
            setTimeout(function() {
              location.href = param.redirect_uri
            }, 500)
          }
        })
      } else {
        this.getWxLogin({ code: param.code, state: param.state }).then(res => {
          const { code, data, msg } = res
          if (code === 200) {
            localStorage.setItem(data.tokenName, data.tokenValue)
            Message.success(msg)
            let index = location.search.includes('redirect_uri')
            if (index) {
              let code = this.locationUrl()
              location.href = decodeURIComponent(
                localStorage.getItem('redirect_uri') + '?' + data.tokenName + '=' + data.tokenValue + code
              )
            } else {
              location.href = decodeURIComponent(
                process.env.VUE_APP_BASE_JUMP + '/?' + data.tokenName + '=' + data.tokenValue + '&url=' + location.origin
              )
            }
          } else {
            Message.success(msg)
          }
        })
      }
    } else {
      this.uesrIsLogin().then(res => {
        if (res.code === 200 && res.data === true) {
          let tokenValue = ''
          let tokenName = ''
          let redirectUri = []
          if (location.search.length > 0) {
            redirectUri = location.search.split('?')[1].split('=')
          }
          let index = redirectUri.includes('redirect_uri')
          for (let i in localStorage) {
            if (i.split('_')[0] === 'token') {
              tokenName = i
              tokenValue = localStorage[i]
            }
          }
          if (index) {
            let code = this.locationUrl()
            location.href = decodeURIComponent(
              localStorage.getItem('redirect_uri') + '?' + tokenName + '=' + tokenValue + code
            )
          } else {
            location.href = decodeURIComponent(
              process.env.VUE_APP_BASE_JUMP + '/?' + tokenName + '=' + tokenValue + '&url=' + location.origin
            )
          }
          // 删除token
          for (const i in localStorage) {
            if (i.split('_')[0] === 'token') {
              localStorage.removeItem(i)
            }
          }
        } else {
          localStorage.clear()
        }
      })
    }
  },
  methods: {
    // 绑定微信登录
    bindWxLogin(params) {
      return new Promise((resolve, reject) => {
        bindWeChat(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 微信登录
    getWxLogin(params) {
      return new Promise((resolve, reject) => {
        wxLogin(params).then(response => {
          resolve(response)
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 控制密码是否可见
    showPwd() {
      if (this.passwordType === 'password') {
        this.passwordType = ''
      } else {
        this.passwordType = 'password'
      }
      this.$nextTick(() => {
        this.$refs.password.focus()
      })
    },
    // 获取Cookies里的值
    getCookies() {
      this.loginForm.username = Cookies.get('Username')
      this.loginForm.password = Cookies.get('Password')
      let checkbox = Cookies.get('Checkbox')
      if (checkbox === 'true') {
        this.loginForm.checkbox = true
      } else {
        this.loginForm.checkbox = false
      }
    },
    // 登录请求
    handleLogin() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          this.loading = true
          this.getToken().then(res => {
            const { code, data, msg } = res
            if (code === 200) {
              localStorage.setItem(data.tokenName, data.tokenValue)
              Message.success(msg)
              setTimeout(function() {
                location.reload()
                this.loading = false
              }, 500)
            } else {
              Message.error(msg)
              this.loading = false
            }
          })
        } else {
          return false
        }
      })
    },
    // 记住密码
    changeClick() {
      this.$refs.loginForm.validate(valid => {
        if (valid) {
          if (this.loginForm.checkbox === true) {
            Cookies.set('Checkbox', this.loginForm.checkbox)
            Cookies.set('Username', this.loginForm.username)
            Cookies.set('Password', this.loginForm.password)
          } else {
            Cookies.set('Checkbox', this.loginForm.checkbox)
            Cookies.remove('Username')
            Cookies.remove('Password')
          }
        } else {
          this.loginForm.checkbox = false
          return false
        }
      })
    },
    // 登录
    getToken() {
      return new Promise((resolve, reject) => {
        let data = {
          name: this.loginForm.username,
          pwd: this.loginForm.password
        }
        login(data)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 从url中查询到指定名称的参数值
    getParam(name, defaultValue) {
      var query = window.location.search.substring(1)
      var vars = query.split('&')
      for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split('=')
        if (pair[0] === name) {
          return pair[1] + (pair[2] ? '=' + pair[2] : '')
        }
      }
      return defaultValue === undefined ? null : defaultValue
    },
    // 检查当前是否已经登录，如果已登录则直接开始跳转，如果未登录则等待用户输入账号密码
    uesrIsLogin() {
      return new Promise((resolve, reject) => {
        isLogin()
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      })
    },
    // 忘记密码
    forgetPassword() {},
    // 执行页面跳转
    locationUrl() {
      let redirectUri = []
      location.search.split('?').forEach(item => {
        if (item) {
          item.split('&').forEach(element => {
            redirectUri.push(element.split('=')[0])
            localStorage.setItem(element.split('=')[0], element.split('=')[1])
          })
        }
      })
      let index = redirectUri.includes('redirect_uri')
      if (index) {
        let codeUrl = ''
        redirectUri.forEach(item => {
          if (item !== 'redirect_uri') {
            codeUrl = codeUrl + '&' + item + '=' + localStorage.getItem(item)
          }
        })
        return codeUrl
      }
    }
  }
}
</script>

<style lang="scss">
$light_gray: #000;
$cursor: #000;

@supports (-webkit-mask: none) and (not (cater-color: $cursor)) {
  .login-container .el-input input {
    color: $cursor;
  }
}

/**
* 重置 element-ui css
*/
.login-container {
  .el-input {
    display: inline-block;
    height: 37px;
    width: 100%;

    input {
      // background: transparent;
      border-left: 0px;
      border-right: 0px;
      border-top: 0px;
      border-radius: 0px;
      -webkit-appearance: none;
      padding: 0px 5px 0px 36px;
      color: $light_gray;
      height: 34px;
      caret-color: $cursor;

      &:-webkit-autofill {
        box-shadow: 0 0 0px 1000px #fff inset !important;
        -webkit-text-fill-color: $cursor !important;
      }
    }
  }

  .el-form-item {
    border: 1px solid rgba(255, 255, 255, 0.1);
    color: #454545;
    margin-bottom: 10px;
  }
}
</style>

<style lang="scss" scoped>
$dark_gray: #889aa4;
$light_gray: #eee;

.login-form {
  // margin-top: 10%;
  width: 100%;

  .huanying-denglu {
    font-size: 20px;
    font-weight: 900;
    color: #000;
  }

  .el-checkbox {
    margin-left: 10px;
  }

  .el-button {
    width: 100%;
    margin-bottom: 15px;
  }

  .show-pwd {
    position: absolute;
    right: 10px;
    top: 7px;
    font-size: 18px;
    color: $dark_gray;
    cursor: pointer;
    user-select: none;
    .el-image {
      width: 36px;
      height: 36px;
    }
  }
}
</style>
