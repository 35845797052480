import Vue from 'vue'

import 'element-ui/lib/theme-chalk/index.css'
import lang from 'element-ui/lib/locale/lang/zh-CN'
import locale from 'element-ui/lib/locale'

import {
  Button,
  Row,
  Col,
  Card,
  Image,
  Form,
  FormItem,
  Input,
  Checkbox,
  Divider
} from 'element-ui'
// 设置语言
locale.use(lang)

Vue.use(Button)
Vue.use(Row)
Vue.use(Col)
Vue.use(Card)
Vue.use(Image)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Divider)
