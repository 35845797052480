<template>
  <div class="login-container">
    <el-row type="flex" justify="center" align="middle">
      <div>
        <login-head />
        <el-card v-show="!isWeChat" class="login-card">
          <!-- 登录表单 -->
          <el-row>
            <el-col :span="15">
              <login-from />
            </el-col>
            <el-col :span="9">
              <login-other @change="getIsWx" />
            </el-col>
          </el-row>
        </el-card>
        <el-card v-show="isWeChat" class="wx-card">
          <i class="el-icon-close close" @click="isWeChat = !isWeChat"></i>
          <div id="wx-login" class="wx-content">
          </div>
        </el-card>
        <el-row type="flex" justify="center" align="middle">
          <span class="record-code"><a target="_blank" href="http://xingyaedu.cn/">郑州兴雅教育科技有限公司</a>&nbsp; © &nbsp;2018-2021 &nbsp;&nbsp;&nbsp;-&nbsp;&nbsp;&nbsp;<a target="_blank" href="https://beian.miit.gov.cn/">豫ICP备20020004号-1</a></span>
        </el-row>
      </div>
    </el-row>
  </div>
</template>

<script>
import LoginHead from '../components/head'
import LoginFrom from '../components/from'
import LoginOther from '../components/other'
// import { Loading } from 'element-ui';
import '../plugins/wxLogin'
import { getWxState } from '../api/login'

export default {
  name: 'Login',
  components: { LoginHead, LoginFrom, LoginOther },
  data() {
    return {
      loading: null,
      isWeChat: false
    }
  },
  created() {
    // this.loading = Loading.service({
    //   target: '.login-container',
    //   text: '正在拼命加载中',
    //   background: 'rgba(255, 255, 255, 1)'
    // })
  },
  methods: {
    getIsWx(type) {
      switch (type) {
        case 'WeChat':
          // 微信
          this.isWeChat = true
          getWxState().then(res => {
            if (res.code === 'HA0200') {
              var obj = new WxLogin({
                id: 'wx-login',
                appid: 'wxcc2bfb36f5d889b8',
                scope: 'snsapi_login',
                redirect_uri: encodeURIComponent(location.href),
                state: res.data
              })
            }
          })
          break
        default:
          break
      }
    }
  }
}
</script>

<style lang="scss" scoped>
// 背景图片
$bg: url('../assets/img/login2.jpg');

.login-container {
  min-height: 100%;
  width: 100%;
  background-image: $bg;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  overflow: hidden;
  height: 100%;

  .el-row {
    height: 100%;
  }

  .login-card {
    position: relative;
    width: 600px;
    height: 360px;
    padding: 10px;

    .head-style {
      .el-row {
        margin-top: -30px;
      }
    }
  }
  .wx-card {
    width: 500px;
    height: 430px;
    position: relative;
    background: #fff;
    .close {
      position: absolute;
      top: 20px;
      right: 20px;
      font-size: 16px;
      cursor: pointer;
      &:hover {
        transform: scale(1.2);
      }

    }
  }
  .wx-content {
    text-align: center;
    width: 300px;
    margin: 0 auto;
  }
}

.record-code {
  margin-top: 20px;
  color: #ccc;
  font-size: 12px;
}
</style>
