<template>
  <!-- 登录界面顶部信息 -->
  <div>
    <el-row type="flex" justify="center">
      <el-image :src="require('@/assets/img/xyLogo.png')" />
    </el-row>
    <div class="head-style">
      <el-row type="flex" justify="center">
        <h1>数字化交互应用体系综合教学平台</h1>
      </el-row>
      <!-- <el-row type="flex" justify="center">
        <h4>交互地球教学应用平台</h4>
      </el-row> -->
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>

.el-image {
  width: 140px;
}

.head-style {

  h1 {
    color: #ccc;
  }
}
</style>
